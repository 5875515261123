import Header from "../components/common/header/header";
import HeroSection from "../components/pages/landing/login/herosection";
import { LoginSection } from "../components/pages/landing/login/loginsection";
import Line from "../components/elements/line";
import { useEffect, useState } from "react";
import Footer from "../components/common/footer";
import { login } from "../store/actions/auth";
import ScrollToTop from "../components/common/ScrollToTop";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";
import constants from "../constants/constants";
import Spinner from "../components/elements/spinner";

import { CardBody, SectionTitle } from "../components/elements/text";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter, BsFacebook, BsEye, BsEyeSlash } from "react-icons/bs";
import Input from "../components/elements/input";
import { Button, OtherLinkButton } from "../components/elements/button";
import image from "../images/signup.png";
import { Link } from "react-router-dom";

import dotenv from "dotenv";
import { useTranslation } from "react-i18next";
import { Message } from "../components/common/messages";
import { store } from "../store/store";
import { useGoogleLogin,GoogleLogin, GoogleOAuthProvider, googleLogout, hasGrantedAllScopesGoogle, hasGrantedAnyScopeGoogle, useGoogleOneTapLogin } from '@react-oauth/google';
import TwitterLogin from 'react-twitter-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import jwtDecode from 'jwt-decode'
const { OAuth2Client } = require('google-auth-library')
export const Login = (props) => {
  const [selectedUserType, setSelectedUserType] = useState("Students");
  return (
    <div className="scrollbar-hidden font-poppins">
      <Header />
      <HeroSection clicked={setSelectedUserType} />
      <Line />
      <LoginSection userType={selectedUserType} />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export const SocialPlatfomsLogin = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userId, setuserId] = useState();
  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.auth.loading);

  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  useEffect(() => {
    if (params.token && token === null) {
      dispatch(actions.getDecodingToken(params.token));
    }
    if (auth?.user?.user_type === null) {
      history.push("/payment/payment");
    } else {
      if (auth?.user?.user_type === constants.studentusertype) {
        if (auth?.user?.isprofilecompleted === 1) {
          history.push("/student");
        } else {
          history.push("/complete");
        }
      } else if (auth?.user?.user_type === constants.teacherusertype) {
        if (auth?.user?.isprofilecompleted === 1) {
          history.push("/login");
        } else {
          history.push("/teacher/user/profile");
        }
      }else if (auth?.user?.user_type === constants.parentusertype) {
        if (auth?.user?.isprofilecompleted === 1) {
          history.push("/login");
        } else {
          history.push("/parent/complete/profile");
        }
      }
    }
  }, [token]);
  return (
    <div className="w-full h-screen flex justify-center">
      {error === null ? (
        <Spinner color={"blue"} size={40} />
      ) : (
        <>
          <Spinner color={"red"} size={40} />
          <span className="text-red font-bol text-lg pt-10">
            Something went wrong
          </span>
        </>
      )}
    </div>
  );
};

export const SocialLoginFailed = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [error, seterror] = useState("The Email is in use on another account");

  const isAuthenticated = useSelector((state) => state.auth.token != null);
  const loginerror = useSelector((state) => state.auth.error);

  const dispatch = useDispatch();

  const enroll = () => {
    const object = {
      names: lastName + " " + firstName,
      username: email,
      email: email,
      dateofbirth: "DEFAULT",
      gender: "DEFAULT",
      account_type: "AccType",
      otherschool: "DEFAULT",
      school_linked_id: "DEFAULT",
      section: "DEFAULT",
      combination: "DEFAULT",
      academic_level: "DEFAULT",
      account_status: "DEFAULT",
      user_type: "AccType",
      guardian_name: "DEFAULT",
      guardian_tel: "phone",
      telephone: phone,
      studentguardian_id: "DEFAULT",
      activation_code: "DEFAULT",
      province: "DEFAULT",
      district: "DEFAULT",
      sector: "DEFAULT",
      guardian_tel_guardian: "DEFAULT",
      country: "DEFAULT",
      refID: "DEFAULT",
      lastname: 0,
      login_method: constants.emaillogintype,
      password,
    };
    dispatch(actions.createAccount(object));
  };
  const responseGoogle = async (response) => {
    //const { email, givenName, familyName, imageUrl } = response?.profileObj;
    if (response.credential != null) {
      const USER_CREDENTIAL = await jwtDecode(response.credential);
      console.log(USER_CREDENTIAL);
     }
  
  }
  const responseTwitter = (response) => {
    console.log(response);
  };
  googleLogout();
  const MyGoogleButton = ({ onClick }) => {
    return (
      <span onClick={onClick}><FcGoogle size="30" /></span>
    );
  }




  const handleFacebookLogin = (response) => {
    console.log(response);
  };

  const renderFacebookButton = ({ onClick, disabled }) => (
    <span onClick={onClick}><BsFacebook size="30" color="#3b5998" /></span>
  );
  useEffect(() => {
    
    if (isAuthenticated) {
      history.push("/verify");
    }
    if (loginerror !== null) {
      seterror(loginerror);
    }
  }, []);

  return (
    <div className="scrollbar-hidden">
      <Header />
      <div className="bg-gray-200 pb-5">
        <div className="flex flex-col md:flex-row md:space-x-2 py-5 justify-center items-center">
          <SectionTitle
            name={`${t("Sign Up to")}`}
            color="blue"
            alignment="center"
          />
          <SectionTitle name="O'Genius Panda" color="blue" alignment="center" />
        </div>
        <div className="lg:w-80% lg:flex flex-row justify-center m-auto lg:max-h-110">
          <div className="hidden lg:block w-1/2 max-h-110 h-auto lg:rounded-l-lg">
            <img
              className="w-full h-full object-cover object-center lg:rounded-l-lg"
              src={image}
              alt=""
            />
          </div>
          <div className="bg-white rounded-xl min-h-signup-card w-90% lg:w-1/2 lg:rounded-l-none lg:rounded-r-lg m-auto lg:m-0 py-5">
            <CardBody
              name={t("Quick Sign up with")}
              color="black"
              alignment="center"
            />
               <div className="flex justify-center space-x-4 pt-1 pb-3">
                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                { <GoogleLogin
                  clientId="470934482733-ifgjmq4jhh7smrk9erfos8crirq9juh8.apps.googleusercontent.com"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  type="icon"
                  ux_mode="popup"
                  shape="circle"
                  cookiePolicy={"single_host_origin"} 
                  isSignedIn={true}    
                  scope="profile" 
                  render={renderProps => (
                    <MyGoogleButton onClick={renderProps.onClick} />
                  )}
                />  }
                {/* { <span onClick={() => login()}><FcGoogle size="30" /></span> } */}
                {/* <GoogleLogin
                  onSuccess={async (credentialResponse) =>  {
                    //const realUserData = await getDecodedOAuthJwtGoogle(credentialResponse) 
                    //console.log(realUserData);
                    if (credentialResponse.credential != null) {
                      const USER_CREDENTIAL = await jwtDecode(credentialResponse.credential);
                      console.log(USER_CREDENTIAL);
                     }
                    
                    
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  
                  render={(renderProps) => (<button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}><span><BsFacebook size="30" color="#3b5998" /></span></button>)}
                /> */}
                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/twitter`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}



                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/facebook`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                <FacebookLogin
                  appId="1764509220385819"
                  autoLoad={false}
                  fields="name,email"
                  callback={handleFacebookLogin}
                  render={renderFacebookButton}
                />
              </div>
            <Message type={"error"} viewable={true} message={error} />
            <CardBody
              name={t("Sign up with your Email or Phone")}
              color="black"
              alignment="center"
            />
            <div className="p-2 lg:px-10">
              <div className="flex space-x-2">
                <Input
                  elementType="input"
                  elementConfig={{
                    type: "text",
                    placeholder: t("First Name"),
                  }}
                  value={firstName}
                  changed={setFirstName}
                  validation={{ required: true }}
                  shouldValidate
                  error={t("First Name is required")}
                />
                <Input
                  elementType="input"
                  elementConfig={{
                    type: "text",
                    placeholder: t("Last Name"),
                  }}
                  value={lastName}
                  changed={setLastName}
                  validation={{ required: true }}
                  shouldValidate
                  error={t("Last Name is required")}
                />
              </div>
              <Input
                elementType="input"
                elementConfig={{
                  type: "email",
                  placeholder: "Email (example@email.com)",
                }}
                value={email}
                changed={setEmail}
                validation={{ isEmail: true }}
                shouldValidate
                error={t("Valid email is required")}
              />
              {/* <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: constants.accounttypes,
              }}
              value={accounttype}
              changed={setAccounttype}
              validation={{ required: true }}
              shouldValidate
              error="Account Type is required"
            /> */}
              <Input
                elementType="phone"
                value={phone}
                changed={setPhone}
                validation={{ isPhone: true }}
                shouldValidate
                error={t("Valid phone number is required")}
              />
              <Input
                elementType="input"
                withIcon
                elementConfig={{
                  type: isShowPassword ? "text" : "password",
                  placeholder: t("Create password"),
                }}
                value={password}
                changed={setPassword}
                validation={{ required: true, minLength: 5 }}
                shouldValidate
                error={t("Password must be at least 5 characters")}
              >
                {!isShowPassword ? (
                  <BsEye
                    className="cursor-pointer"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  />
                ) : (
                  <BsEyeSlash
                    className="cursor-pointer"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  />
                )}
              </Input>
              <Input
                elementType="input"
                withIcon
                elementConfig={{
                  type: isShowConfirmPassword ? "text" : "password",
                  placeholder: t("Confirm password"),
                }}
                value={comfirmPassword}
                changed={setComfirmPassword}
                validation={{
                  required: true,
                  equal: true,
                  equivalence: password,
                }}
                shouldValidate
                error={t("Passwords do not match")}
              >
                {!isShowConfirmPassword ? (
                  <BsEye
                    className="cursor-pointer"
                    onClick={() =>
                      setIsShowConfirmPassword(!isShowConfirmPassword)
                    }
                  />
                ) : (
                  <BsEyeSlash
                    className="cursor-pointer"
                    onClick={() =>
                      setIsShowConfirmPassword(!isShowConfirmPassword)
                    }
                  />
                )}
              </Input>
              <div className="flex flex-col pt-3 pb-2">
                <CardBody
                  name={t("By signing up to O'Genius Panda,")}
                  color="black"
                  alignment="center"
                />
                <div className="flex flex-row space-x-2 flex-wrap items-center justify-center">
                  <CardBody
                    name={t("I accept")}
                    color="black"
                    alignment="center"
                  />
                  <OtherLinkButton
                    color="blue"
                    to="/termsofservice"
                    name={t("Terms of service")}
                  />
                  <CardBody name={t("and")} color="black" alignment="center" />
                  <OtherLinkButton
                    color="blue"
                    to="/privacypolicy"
                    name={t("Privacy Policy")}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center">
                <Button
                  name={t("Sign Up")}
                  outline="true"
                  color="blue"
                  clicked={enroll}
                />
              </div>
              <div className="flex justify-center space-x-2">
                <CardBody
                  name={t("Already have an account?")}
                  color="black"
                  alignment="center"
                />
                <OtherLinkButton color="blue" to="/login" name={t("Login")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};
