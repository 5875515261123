import { updateObject } from "../../shared/utility";
import {
  SCHOOL_USERS_SEND_FEEDBACK_INIT,
  SCHOOL_USERS_SEND_FEEDBACK_SUCCESS,
  SCHOOL_USERS_SEND_FEEDBACK_FAILED

} from "../actions/student.dashboard.user.feedback";
//--
const initialReducer = {
  school_user_feedback_loading: false,
  message: "",
};

function school_users_send_feedback_init(state, action) {
  return updateObject(state, {
    school_user_feedback_loading: true,
  });
}
function school_users_send_feedback_success(state, action) {
  console.log(action.payload)
  return updateObject(state, {
    school_user_feedback_loading: false,
    message: action.payload,
  });
}
function school_users_send_feedback_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_user_feedback_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SCHOOL_USERS_SEND_FEEDBACK_INIT:
      return school_users_send_feedback_init(state, action);
    case SCHOOL_USERS_SEND_FEEDBACK_SUCCESS:
      return school_users_send_feedback_success(state, action);
    case SCHOOL_USERS_SEND_FEEDBACK_FAILED:
      return school_users_send_feedback_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
