import Header from '../components/common/header/header';
import Hero from '../components/common/hero';
import ScrollToTop from '../components/common/ScrollToTop';
import PageContent from '../components/pages/landing/faq/pagecontent';
export const FAQ = (props) => {
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <Hero
        page="faq"
        title="Frequently Asked Questions"
        body=""
      />
      <PageContent />
      <ScrollToTop />
    </div>
  );
};
