import React from 'react';
import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from '../../../elements/text';

const MessageCard = (props) => {
  return (
    <div
      className="bg-white shadow p-2 my-3 grid grid-cols-12 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="mx-auto col-span-2 w-16 h-16 bg-gray-200 rounded-full"></div>
      <div className="col-span-8">
        <CardTitle name={props.sender} />
        <CardBody name={props.content.substring(0, 100) + '...'} />
      </div>
      <div className="col-span-2 flex flex-col justify-between items-center">
        <SmallText name={props.time} color="blue" />
        {props.new && (
          <CardSubText name="New" color="yellow" additional="font-bold" />
        )}
      </div>
    </div>
  );
};

export default MessageCard;
