import Footer from "../components/common/footer";
import Header from "../components/common/header/header";
import Carousel from "../components/pages/landing/home/carousel";
import Whoisitfor from "../components/pages/landing/home/whoisitfor";
import Valueproposition from "../components/pages/landing/home/valueproposition";
import Howitworks from "../components/pages/landing/home/howitworks";
import Impact from "../components/pages/landing/home/impact";
import Testimony from "../components/pages/landing/home/testimony";
import Enroll from "../components/pages/landing/home/enroll";
import Partners from "../components/pages/landing/home/partners";
import ScrollToTop from "../components/common/ScrollToTop";
import { useEffect } from "react";
import * as actions from "../store/actions/";
import { useDispatch } from "react-redux";

export const Home = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    redirectToOpandaRw();
    dispatch(actions.getHomeCarousel());
    dispatch(actions.getHomeHowItWork());
    dispatch(actions.getHomeWhoIsOpandaFor());
    dispatch(actions.getHomePartners());
    dispatch(actions.getHomeImpacts());
    dispatch(actions.getHomeValuePropositions());
    dispatch(actions.getHomeTestimonies());
    // dispatch(actions.logout());
  });
  function redirectToOpandaRw() {
    const currentUrl = window.location.href.toLowerCase();
  
    if (
      currentUrl.includes('opanda.xyz') ||
      currentUrl.includes('http://opanda.rw') ||
      currentUrl.includes('http://www.opanda.rw')
    ) {
      window.location.href = 'https://www.opanda.rw';
    }
  }
  
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <Carousel />
      <Whoisitfor />
      <Valueproposition />
      <Howitworks />
      <Impact />
      <Testimony />
      <Enroll />
      <Partners />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
