import { combineReducers } from "redux";
import contentmodalreducer from "./contentmodalreducer";
import reducer from "./sidebarreducer";
import completeSignupReducer from "./completeSignupReducer";
import languagesReducer from "./language";
import notificationsReducer from "./notifications";
import authReducer from "./auth";
import autoquestionsReducer from "./pandaquestionsauto";
import autoquestionsAlgoV2Reducer from "./pandaautotestalgov2";
import testaiusersanswersReducer from "./Pandatestaiusersanswers";
import testmapReducer from "./Pandatestmap202003";
import paymentReducer from "./payment";
import previewpandaReducer from "./previewpanda";
import homeReducer from "./home";
import featuresReducer from "./features";
import settingsReducer from "./settingsReducer";
import paymentReducerMenu from "./paymentReducer";
import programReducer from "./common/programs";
import communityReducer from "./community";
import teacherclassesReducer from "./features/teacher/classes";

import testreducerbyPacy from "./testreducerbyPacy";

import studentDashboardhomePageReducer from "./student.dashboard.homepage";
import studentDashboardPrograms from "./student.dashboard.programs";
import studentDashboardCurriculumCbc from "./student.dashboard.curriculum.cbc";
import studentDashboardDatacollection from "./student.dashboard.datacollection";
import studentDashboardPastpapers from "./student.dashboard.pastpapers";
import extraPackage from "./extrapackage";

import profileReducer from "./profileinformation";
import studentDashboardTests from "./student.dashboard.tests";
import studentDashboardTestsSubjectsUnits from "./student.dashboard.tests.subjects.units";

import studentDashboardCurriculumCambrige from "./student.dashboard.curriculum.cambrige";
import autoTestProgressList from "./features/test.progress";
import autotestresult from "./features/test.progress.result";
import filesReducer from "./files";

import studentAttemptAchallenges from "./student.attempt.challenges";
import studentDashboardSimulations from "./student.dashboard.simulations";
import studentDashboardLibrary from "./student.dashboard.library";
import teacherDashboardLibrary from "./teacher.dashboard.library";
import studentDashboardCommunity from "./student.dashboard.community";

import schoolDashboardUsers from "./school.dashboard.users";
import schoolUserDetails from "./school.dashboard.user.details";
import schoolRegisteredUser from "./school.dashboard.user.registered";
import schoolDashboardClasses from "./school.dashboard.classes";
import userReducer from "./users";
import loadToken from "./token";
import referal from "./student.dashboard.settings.link";
import notificationsCountUpdates from "./notificationsCountUpdates";
import feedback from "./student.dashboard.user.feedback.js";
import studentDashboardOffline from "./student.dashboard.settings.offline";

export default combineReducers({
  contentmodalreducer,
  reducer,
  completeSignup: completeSignupReducer,
  languages: languagesReducer,
  notifications: notificationsReducer,
  auth: authReducer,
  autoquestions: autoquestionsReducer,
  autoquestionsalgov2: autoquestionsAlgoV2Reducer,
  testaiusersanswers: testaiusersanswersReducer,
  testmaps: testmapReducer,
  payment: paymentReducer,
  features: featuresReducer,
  previewpanda: previewpandaReducer,
  studentDashboardhomePage: studentDashboardhomePageReducer,
  home: homeReducer,
  pacytest: testreducerbyPacy,
  Settings: settingsReducer,
  paymentmenu: paymentReducerMenu,
  programs: programReducer,
  community: communityReducer,
  classtab: teacherclassesReducer,
  classtab: teacherclassesReducer,
  studentDashboardPrograms: studentDashboardPrograms,
  studentDashboardCurriculumCbc: studentDashboardCurriculumCbc,
  studentDashboardDatacollection: studentDashboardDatacollection,
  profile: profileReducer,
  studentDashboardPastpapers: studentDashboardPastpapers,
  studentDashboardTests: studentDashboardTests,
  studentDashboardTestsSubjectsUnits: studentDashboardTestsSubjectsUnits,

  studentDashboardCurriculumCambrige: studentDashboardCurriculumCambrige,
  autoTestProgressList: autoTestProgressList,
  autotestresult: autotestresult,
  files: filesReducer,
  studentAttemptAchallenges: studentAttemptAchallenges,
  studentDashboardSimulations: studentDashboardSimulations,
  studentDashboardLibrary: studentDashboardLibrary,
  teacherDashboardLibrary: teacherDashboardLibrary,
  studentDashboardCommunity: studentDashboardCommunity,
  schoolDashboardUsers: schoolDashboardUsers,
  schoolUserDetails: schoolUserDetails,
  schoolRegisteredUser: schoolRegisteredUser,
  usersInformation: userReducer,
  usertoken: loadToken,
  userReferal: referal,
  userFeedback: feedback,
  notificationsCountUpdates: notificationsCountUpdates,
  packageExtras: extraPackage,
  studentDashboardOffline: studentDashboardOffline
});
